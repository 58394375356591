export function getMenuData(headerLinks){
    const topLevelMenu = (headerLinks["contentfulMenuItem"]["subMenuItems"]);
    return formatHeaderMenuData(topLevelMenu, 1);
}

export function getFooterMenuData(headerLinks){
    const topLevelMenu = (headerLinks["contentfulMenuItem"]["subMenuItems"]);
    return formatHeaderMenuData(topLevelMenu, 1, false);
}
  
function formatHeaderMenuData(menuItems, linkLevel, linksToItems = true){
    let returnObject = [];
    const linkLevelLimit = 3;
  
    menuItems.forEach(item => {
      let returnItems = getLinkData(item);
  
      if (item.subMenuItems && linkLevel < linkLevelLimit) {
        returnItems.linkLevel = linkLevel;
        returnItems.links = formatHeaderMenuData(item.subMenuItems, linkLevel + 1);
      }
      if (returnItems.links) {
        let hasChildItems = false;
  
        returnItems.links.forEach(child => {
          if (child.links) {
            hasChildItems = true;
          }
        }); 
  
        if (linksToItems) {
            if (hasChildItems) {
                returnItems.items = returnItems.links;
                delete returnItems.links;
            } else if (linkLevel === 1) {
                returnItems.items = [
                    {
                        links: returnItems.links
                    }
                ];
                delete returnItems.links;
            }
        }
      }
      returnObject.push(returnItems);
    });
  
    return returnObject;
}
  
function getLinkData(menuItem){
    let linkObject = {};
    linkObject.title = menuItem.title;

    if (menuItem?.supportingButtonText) {
        linkObject.supportingLink = {
            text: menuItem.supportingButtonText,
        };
    }
    
    if (menuItem.description?.description) {
      linkObject.description = menuItem.description?.description;
    }
  
    if (menuItem.pageReference?.slug) {
      linkObject.url = menuItem.pageReference.slug;
    } else if (menuItem.url) {
      linkObject.url = menuItem.url;
    }
  
    if (linkObject.url) {
      if (linkObject.url.startsWith('http://') || linkObject.url.startsWith('https://')) {
        linkObject.target = "_blank";
      }
      else{
        linkObject.url = formatLink(linkObject.url);
      }
    } else {
      linkObject.url = '#';
    }
  
    if (menuItem.icon) {
      if (menuItem.icon?.fluid?.src) {
        linkObject.linkIcon = menuItem.icon.fluid.src;
      } else if (menuItem.icon?.file?.url) {
        linkObject.linkIcon = menuItem.icon.file.url;
      }
      linkObject.linkIconAlt = "";
      if (menuItem.icon?.description) {
        linkObject.linkIconAlt = menuItem.icon?.description;
      } else if (menuItem.icon?.title){
        linkObject.linkIconAlt = menuItem.icon?.title;
      }
    }
  
    return linkObject;
}
  
function formatLink(link){
    link = link.replace(/^\/+|\/+$/g, '');
    return `/${link}/`;
};