import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const HeroImage = () => {
  const data = useStaticQuery(graphql`
{
  allFile(filter: {relativeDirectory: {eq: "footer"}, name: {eq: "ServiceProviderBadge"}}) {
    edges {
      node {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
}


  `)
  return <Image fixed={data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fixed} alt="Visa Service provider logo" />
}

export default HeroImage
