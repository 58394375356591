export const LINKS = {
  index: "/",
  blog: "/blog/",
  gateway: "/payment-orchestration-platform/",
  ourStory: "/our-story/",
  partnerships: "/partnerships/",
  paymentOrchestration: "/payment-orchestration-platform/",
  surchargeInspector: "/surcharge-inspector/",
  bnplAggregator: "/apexx-connect/",
  api: "https://sandbox.apexx.global/atomic/redoc/api/doc",
  reporting: "/reporting/",
  privacyPolicy: "/privacy-policy/",
  cookiesPolicy: "/privacy-policy/#cookies-policy",
  linkedin:"https://www.linkedin.com/company/apexxglobal/",
  twitter:"https://twitter.com/apexx_global/",
  contactUs : "/contact-us/",
  support:"https://support.apexx.global/support/home/",
  termsAndConditions: "/terms-and-conditions/",
  icoWebsite: "https://ico.org.uk/",
  countryReports: "/country-reports/",
  careers: "https://careers.apexx.global/",
  pressAndMedia: "/press-and-media/",
  caseStudies: "https://insights.apexx.global/case-study-enterprise-clients-routing-cascading",
  dynamicRouting: "/dynamic-routing/",
  apm: "/apm/",
  productsandservices: "/products-and-services/",
  costSavingCalculator: "/cost-saving-calculator/"
}
