import styled from "styled-components"
import { colors, fonts } from "../../../../utils/styles"

const { primaryBlue, secondaryBlue2 } = colors

export const StyledFooter = styled.footer`
  background-color: ${primaryBlue};

  small,
  address {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8rem;
  }
  small {
    font-size: 0.6rem;
  }
  span {
    color: #8fc5ea;
    font-size: 0.8rem;
  }

  h5 {
    color: ${secondaryBlue2};
    font-family: ${fonts.nunito};
  }
  ul {
    li {
      list-style: none;
      a {
        font-size: 0.8rem;
      }
      a:hover {
        color: #a9dbef !important;
      }
    }
  }
  .social-media {
    a {
      border: 1px solid #6be2e5;
      padding: 0px 4px 0px 4px;
      border-radius: 5px;
      svg {
        fill: #ffffff;
      }
    }
  }
  .globe-icon svg {
      fill: #a9dbef;
  }
  .divider {
    width: 90%;
    border-bottom: 1px #a9dbef solid;
  }
`
