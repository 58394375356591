import React from "react"
import { ButtonContainer } from "./ApButton.styles"

export const ApButton = (props) => {
  const { children, className } = props

  return (
    <ButtonContainer className={`btn ${className}`} {...props}>
      {children}
    </ButtonContainer>
  )
}

export default ApButton
