import { Col, Container, Row } from "react-bootstrap"
import { FaLinkedinIn, FaTwitter } from "react-icons/fa"

import ApButton from "../../Common/ApButton/ApButton"
import { FOOTER_SECTIONS } from "../../../../data/footer.data"
import { IoIosGlobe } from "react-icons/io"
import { LINKS } from "../../../../data/pageLinks.data"
import Logo from "../../../Global/Layout/Logo/Logo"
import React from "react"
import { StyledFooter } from "./Footer.styles"
import { useEffect } from "react"
import IsoLogo from "./iso-27001"
import ServiceProvider from "./service-provider"
import { getFooterMenuData } from "../formatNavigationLinks"
import { useStaticQuery } from "gatsby"

const Footer = () => {
  //const footerSections = FOOTER_SECTIONS;
  const FOOTER_LINKS = getFooterMenuData(useGetFooterLinks());
  
  useEffect(() => {
    const socialTags = ["linkedin", "twitter"]
    const elementsToBeAddedWithId = ["svg", "path", "a"];
    // fetch all elements with social Id share-button-${socialTag}
    const socialButtons = document.querySelectorAll(socialTags.map(st =>`.social-${st}`));
    // itereate over all social Buttons
    socialButtons.forEach((socialButton, buttonIndex) => {
      // fetch known element Tags
      elementsToBeAddedWithId.forEach(tagName => {
        const elements = socialButton.getElementsByTagName(tagName) || [];
        // set ids to them
        for (let element of elements) {
          element.setAttribute('id', `apexx-social-${socialTags[buttonIndex%socialTags.length]}`);
        }
      });
    })
  });
  return (
    <StyledFooter className="pt-5 pb-5 poppins">
      <Container className="text-left">
        <Row className="text-center text-lg-left">
          <Col xs={12} lg={3}>
            <Logo variant="light" />
            <address>
              First Floor
              <br />
              69 Turnmill Street
              <br />
              Farringdon
              <br />
              London, EC1M 5RR
              <br />
              United Kingdom
            </address>
            <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
              <div><IsoLogo /></div>
              <div className="pl-3"><ServiceProvider /></div>  
            </div>
          </Col>
          <Col xs={12} lg={9}>
            <Row className="pb-5 pr-3">
              {FOOTER_LINKS.map((section, index) => {
                return (
                  <Col
                    className="text-left pl-5 pt-5"
                    key={index}
                    xs={6}
                    lg={4}
                  >
                    <h5>{section.title}</h5>
                    <ul className="pl-0">
                      {section.links.map((link, index) => {
                        return (
                          <li
                            className="d-flex align-items-center mb-2"
                            key={index}
                          >
                            <a href={link.url} className="text-white" target={link.target}>
                              {link.title}
                              {link.supportingLink && (
                                <ApButton size="xs" className="ml-2">
                                  {link.supportingLink.text}
                                </ApButton>
                              )}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </Col>
                )
              })}
              <Col xs={12} className="d-lg-none d-block text-left">
                <Row className="social-media p-3">
                  <Col>
                    <a href={LINKS.linkedin} className="m-3 " target="_blank">
                      <FaLinkedinIn />
                    </a>
                    <a href={LINKS.twitter} className = "" target="_blank">
                      <FaTwitter />
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div className="d-flex justify-content-center align-items-center">
                      <hr className="divider" />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="globe-icon ml-4">
                      <IoIosGlobe />
                      <span className="ml-2">United Kingdom - Eng</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-between">
          <div className="ml-4 ml-lg-0">
            <span>&copy; {new Date().getFullYear()} APEXX Fintech Limited - 10131902</span>
            {/* <small>
              Authorised and regulated as an electronic money institution by the
              UK Financial Conduct Authority under number 900816.
            </small> */}
          </div>
          <div className="social-media d-none d-lg-flex align-items-center">
            <div className="globe-icon ">
              <IoIosGlobe className="mr-2" />
              <span className="mr-2">United Kingdom - Eng</span>
            </div>
            <a href={LINKS.linkedin} className="mr-2 " target="_blank">
              <FaLinkedinIn />
            </a>
            <a href={LINKS.twitter} className="mr-2 " target="_blank">
              <FaTwitter />
            </a>
          </div>
        </div>
        
      </Container>
    </StyledFooter>
  )
}

export const useGetFooterLinks = () => {
  const headerLinks = useStaticQuery(graphql`
    {
      contentfulMenuItem(title: {eq: "Footer Menu"}) {
        title
        subMenuItems {
          title
          pageReference {
            slug
          }
          url
          subMenuItems {
            title
            supportingButtonText
            pageReference {
              slug
            }
            url
          }
        }
      }
    }
  `);

  return (headerLinks);
}

export default Footer
