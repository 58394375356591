import React from "react"

const Logo = ({ variant, className }) => {
  const fill = variant === "dark" ? "#134056" : "#fefefe"
  return (
    <svg
      width="200px"
      height="60px"
      viewBox="0 0 473 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g id="Artboard" transform="translate(-68.000000, -52.000000)">
          <g
            id="Group-39"
            transform="translate(-8.000000, 50.000000)"
            fill={fill}
          >
            <g id="Group-19" transform="translate(76.000000, 2.000000)">
              <polygon
                id="Fill-1"
                points="39.2643402 0 34.8153762 6.80643271 0 60 8.99934569 60 39.2182116 13.6704864 40.1656489 15.1445407 49.6737186 29.9638648 53.1117775 35.2887558 68.9928026 60 78 60 58.5402399 29.8593658 54.1812432 23.0773488 44.6257361 8.30718146 43.6753544 6.83475487 39.2646674 0"
              />
              <polygon
                id="Fill-2"
                points="29 60 37.7409961 60 48 43.8338297 43.7089713 37"
              />
              <polygon
                id="Fill-3"
                points="69.4363661 0 59 16.2132641 63.3091491 23 78 0"
              />
              <g id="Group-18" transform="translate(102.000000, 14.000000)">
                <path
                  d="M97.5609808,17.5172516 L105.307357,17.5172516 C109.531675,17.5172516 111.639763,15.0343147 111.639763,10.0664832 C111.639763,7.69873579 111.138473,5.96208316 110.135894,4.85391474 C109.131686,3.74215684 107.520653,3.19003053 105.307357,3.19003053 L97.5609808,3.19003053 L97.5609808,17.5172516 Z M105.351004,20.5359989 L97.5609808,20.5359989 L97.5609808,30.8665042 L94.15195,30.8665042 L94.15195,0.171609474 L105.351004,0.171609474 C108.688049,0.171609474 111.153457,0.984135789 112.744946,2.61212526 C114.341972,4.24011474 115.138694,6.72370421 115.138694,10.0664832 C115.138694,17.04442 111.872982,20.5359989 105.351004,20.5359989 L105.351004,20.5359989 Z"
                  id="Fill-4"
                />
                <polygon
                  id="Fill-6"
                  points="179.942524 30.8665368 179.942524 0.171642105 198.583401 0.171642105 198.583401 3.19006316 183.351555 3.19006316 183.351555 13.7920632 195.927771 13.7920632 195.927771 16.7631684 183.351555 16.7631684 183.351555 27.8520316 198.583401 27.8520316 198.583401 30.8665368"
                />
                <polygon
                  id="Fill-8"
                  points="22.4074228 3.64834105 20.1625308 0.142730526 17.8948381 3.63365684 0.167292011 30.8992989 4.75023319 30.8992989 20.1364729 7.15362526 35.3008943 30.8992989 39.8883956 30.8992989"
                />
                <polygon
                  id="Fill-10"
                  points="281.660757 0.139010526 261.661284 30.8968842 266.248459 30.8968842 286.095819 0.139010526"
                />
                <polygon
                  id="Fill-12"
                  points="266.100418 0.139010526 286.100217 30.8968842 281.512715 30.8968842 261.665355 0.139010526"
                />
                <polygon
                  id="Fill-14"
                  points="366.280385 0.139010526 346.281889 30.8968842 350.868413 30.8968842 370.71675 0.139010526"
                />
                <polygon
                  id="Fill-16"
                  points="350.721316 0.139010526 370.719812 30.8968842 366.132636 30.8968842 346.283973 0.139010526"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  variant: "dark",
}

export default Logo
