import { createGlobalStyle } from "styled-components"
import { fonts, colors } from "../../../utils/styles"
import "../../../components/typography.css"

export const GlobalStyles = createGlobalStyle`
@media (min-width: 992px) {
  html  {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
  }
}

.row{
	max-width: 100%;

	&.no-offset{
		margin-left: 0;
		margin-right: 0;
	}
}

main{
	padding-top: ${(props) => (props.headerTransparent ? "0" : "86px")};
}

body {
  font-family: 'Nunito Sans', 'Poppins';
  color:#262626;
  background:#fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height:100vh;
  min-width:320px;
  overflow-x: hidden;
  .container{
	padding-left:0px;
	padding-right:0px;
	@media screen and (min-width:1200px) {
		max-width: 1170px;
	}
  }
  #cookie-popup-icon-container{
	position: fixed;
	z-index: 10000;
	bottom: 0;
	right: 0;
	#cookie-popup-icon{
		border-style: solid;
		border-width: 0 0 65px 65px;
		border-color: transparent transparent #55baa4 #0000;
	}
	.cookie-icon{
		position: absolute;
		bottom: 10px;
		right: 8px;
		width: 22px;
		height: 22px;
	}
	&:hover{
		cursor:pointer;
	}
  }
}
h1{
  font-family: ${fonts.nunito};
  color:${colors.primaryBlue};
}
p{
  font-family: ${fonts.poppins};
  color:${colors.primaryBlue};
}
a{
  cursor: pointer;
  &:hover{
    text-decoration:none

  }
}
.color-fefefe{
  color: #fefefe;
}
.nunito {
  font-family:${fonts.nunito}
}
.poppins{
  font-family:${fonts.poppins}
}
.ambit{
	font-family: Ambit;
}
.primary-blue-background {
  background-color: ${colors.primaryBlue};
}
.primary-blue-text{
  color:${colors.primaryBlue};
}
.color-sky-blue{
  color: #6BE2E5;
}
.secondary-blue-2-background{
  background-color: ${colors.secondaryBlue2}
}
.secondary-blue-text{
  color: ${colors.secondaryBlue}
}
.secondary-blue-2-text{
  color: ${colors.secondaryBlue2}
}
.secondary-blue-3-background{
  background-color: ${colors.secondaryBlue3}
}
.secondary-blue-4-background{
  background-color: ${colors.secondaryBlue4}
}
.white-background{
  background-color: #ffffff;
}
.bg-gray{
  background-color:#F4F4F4
}
.blue-aqua{
  color:#4CB9EF
}
.text-picton-blue{
  color:#45B8F1
}

.m-fadeOut {
  transform:scale(0);
  transition:transform linear 1s;
}
.m-fadeIn {
  transform:scale(1);
  transition:transform linear 1s;
}

.CookieConsent {
	padding: 20px;
	font-size:18px;
	box-sizing:border-box;
	left:50% !important;
	top:50% !important;
	transform: translate(-50%,-50%) !important;
	width:(100% - 40px) !important;
	bottom:auto !important;
	background: none !important;
	max-width:600px;
}

.CookieConsent::after {
	content: '';
	position: absolute;
	top: -1000px;
	left: -1000px;
	z-index: -6;
	width: 9999px;
	height: 9999px;
	background: rgba(255,255,255,0.6);
}

.CookieConsentOverlay {
	content:'';
	display:block;
	height:99999px;
	width:99999px;
	left:-1000px;
	top:-1000px;
	background: rgba(255,255,255,0.6);
}

.CookieConsent > div {
	display:block;
	flex:none !important;
	max-width:100%;
	background: #144056 !important;
	padding: 40px 40px 20px !important;
	margin: 0 !important;
	width: 100% !important;
}

.CookieConsent > div:last-child {
	padding: 21px 40px 40px !important;
	margin-top: -1px !important;
}

.CookieConsentCopy a {
	text-decoration: underline;
}

#rcc-confirm-button,#rcc-decline-button {
	border-radius: 15px !important;
	text-transform: capitalize !important;
	background: #252526 !important;
	padding-left: 40px !important;
	padding-right: 40px !important;
	padding-top:10px !important;
	padding-bottom: 10px !important;
	margin: 0!important;
	color: #ffffff !important;

	@media screen and (max-width:800px) {
		display:block;
	}
}

#rcc-confirm-button {
	margin-right:40px !important;
	background: #1f7fc3 !important;
	@media screen and (max-width:800px) {
		margin-right: 0 !important;
	}
}

#rcc-decline-button {
	@media screen and (max-width:800px) {
		margin-top: 20px !important;
	}
}

#gatsby-focus-wrapper .react-cookie-notice-container{
	.react-cookie-notice-content{
		ul{
			background: rgb(20, 64, 86);
			padding: 1rem 0;
			li label, p{
				color: #ffffff;
			}
			p{
				margin-top: 0.8rem;
			}
		}
		@media screen and (max-width:550px) {
			margin-bottom: 1rem;
		}
	}
	.react-cookie-notice-buttons{
		@media screen and (max-width:550px) {
			display: block;
			.react-cookie-notice-button-secondary, .react-cookie-notice-button-primary{
				margin: 30px auto 0;
				display: block;
			}
		}
	}
}

`
