import { LINKS } from "./pageLinks.data"

export const FOOTER_SECTIONS = [
  {
    title: "Company",
    links: [
      { title: "About Us", url: LINKS.ourStory },
      { title: "Partnerships", url: LINKS.partnerships },
      {
        title: "Careers",
        url: LINKS.careers,
        supportingLink: {
          text: "Hiring",
        },
      },
      { title: "Contact", url: LINKS.contactUs },
    ],
  },
  {
    title: "Product",
    links: [
      { title: "Payment Orchestration Platform", url: LINKS.gateway },
      { title: "Reporting", url: LINKS.reporting },
      {
        title: "Developers",
        url: LINKS.api,
        target: "_blank",
      },
      { title: "Support", url: LINKS.support, target: "_blank" },
      // { title: "Knowledge Hub", url: "#" },
    ],
  },
  {
    title: "Legal",
    links: [
      { title: "Terms & Conditions",  url: LINKS.termsAndConditions },
      { title: "Privacy Policy & Cookies", url: LINKS.privacyPolicy },
      { title: "Products & Services", url: LINKS.productsandservices },
    ],
  },
]
