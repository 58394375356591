import styled, { css } from "styled-components"
import Button from "react-bootstrap/Button"
import { colors } from "../../../../utils/styles"

const { secondaryBlue, primaryBlue, secondaryBlue5 } = colors

// const capturedStyles = css`
//   background-color: rgba(80, 227, 194, 0.3);
//   color: #1ceea0;
// `

const expiredStyles = css`
  background-color: rgba(170, 170, 170, 0.3);
  color: #ebecec;
`
const inProgressStyles = css`
  background-color: rgba(65, 171, 245, 0.3);
  color: #1cbbee;
`
const mainButton = css`
  background: ${secondaryBlue};
  padding-left: 25px;
  padding-right: 25px;
  color: #ffffff;
`

const inverseStyles = css`
  border: 1px solid #1e7fc2;
  color: ${primaryBlue};
  padding-left: 25px;
  padding-right: 25px;
`
const green = css`
  background: #55baa3;
  color: #ffffff;
  border-radius: 15px;
  &:disabled {
    opacity: 1;
  }
  &:hover {
    cursor: default;
    color: #ffffff;
    background-color: #42a38d;
  }
`
const greenTwo = css`
  background: #55baa3;
  color: #ffffff;
  border-radius: 10px;
  &:hover {
    color: #ffffff;
    background-color: #42a38d;
  }
`
const greenTwoLarge = css`
  background: #55baa3;
  color: #ffffff;
  border-radius: 15px;
  text-transform: capitalize;
  border-radius: 15px;
  padding: 12px 30px;
  &:hover {
    color: #ffffff;
    background-color: #42a38d;
  }
`
const greenPrimary = css`
  background: #55baa3;
  color: #ffffff;
  border-radius: 15px;
  padding-left: 25px;
  padding-right: 25px;
  &:hover {
    color: #ffffff;
    background-color: #42a38d;
  }
`
const greenSecondary = css`
  background: #144056;
  color: #cee7e9;
  border-radius: 15px;
  padding: 10px 40px;
  &:hover {
    color: #ffffff;
    background-color: #42a38d;
  }
`

const greenSecondaryLarge = css`
  background: #144056;
  color: #cee7e9;
  border-radius: 15px;
  text-transform: capitalize;
  border-radius: 15px;
  padding: 12px 30px;
  &:hover {
    color: #cee7e9;
    background-color: #42a38d;
  }
`

const lightBlue = css`
  background: ${secondaryBlue5};
  color: #ffffff;
  border-radius: 10px;
  &:hover {
    color: #ffffff;
  }
`

const lightGreen = css`
  background: #6ECDD6;
  color: #ffffff;
  border-radius: 10px;
  padding: .375em 2em;
  border-radius: 10px;
  &:hover {
    color: #ffffff;
    background: #55B4BD;
  }
`

const lightGreenLarge = css`
  background: #6ECDD6;
  color: #ffffff;
  border-radius: 15px;
  text-transform: capitalize;
  border-radius: 15px;
  padding: 12px 30px;
  &:hover {
    color: #ffffff;
    background: #55B4BD;
  }
`

const xs = css`
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8rem;
  padding-top: 0px;
  padding-bottom: 0px;
`

const getButtonStyles = (props) => {
  const { variant } = props
  switch (variant) {
    case "expired":
      return expiredStyles
    case "inProgress":
      return inProgressStyles
    case "inverse":
      return inverseStyles
    case "green":
      return green
    case "greenTwo":
      return greenTwo
    case "greenTwoLarge":
      return greenTwoLarge
    case "greenPrimary":
      return greenPrimary
    case "lightGreen":
      return lightGreen
    case "lightGreenLarge":
      return lightGreenLarge
    case "lightBlue":
      return lightBlue
    case "greenSecondary":
      return greenSecondary
    case "greenSecondaryLarge":
      return greenSecondaryLarge
    default:
      return mainButton
  }
}
const getButtonSize = (props) => {
  const { size } = props
  switch (size) {
    case "xs":
      return xs
    default:
      return
  }
}

export const ButtonContainer = styled(Button)`
  border-radius: 15px;
  text-transform: capitalize;
  ${getButtonStyles}
  ${getButtonSize}
`
