export const colors = {
  primaryBlue: `#144056`, // APEXX Blue
  primaryBlue2: `#72b4b4`,
  secondaryBlue: `#1f7fc3`,
  secondaryBlue2: `#55b9a3`,
  secondaryBlue3: `#3b5Ca7`,
  secondaryBlue4: `#4c6fb0`,
  secondaryBlue5: `#4Cb9ef`
}

export const fonts = {
  nunito: "Nunito Sans",
  poppins: `Poppins`,
}
